import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "currentColor",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M16.7963 10.4702C16.4563 11.0902 15.7963 11.5002 15.0463 11.5002H7.59634L6.49634 13.5002H18.4963V15.5002H6.49634C4.97634 15.5002 4.01634 13.8702 4.74634 12.5302L6.09634 10.0902L2.49634 2.50024H0.496338V0.500244H3.76634L4.70634 2.50024H19.5063C20.2663 2.50024 20.7463 3.32024 20.3763 3.98024L16.7963 10.4702ZM17.8063 4.50024H5.65634L8.02634 9.50024H15.0463L17.8063 4.50024ZM6.49634 16.5002C5.39634 16.5002 4.50634 17.4002 4.50634 18.5002C4.50634 19.6002 5.39634 20.5002 6.49634 20.5002C7.59634 20.5002 8.49634 19.6002 8.49634 18.5002C8.49634 17.4002 7.59634 16.5002 6.49634 16.5002ZM14.5063 18.5002C14.5063 17.4002 15.3963 16.5002 16.4963 16.5002C17.5963 16.5002 18.4963 17.4002 18.4963 18.5002C18.4963 19.6002 17.5963 20.5002 16.4963 20.5002C15.3963 20.5002 14.5063 19.6002 14.5063 18.5002Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}